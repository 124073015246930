export default defineNuxtRouteMiddleware(() => {
  const user = useUserStore()

  if (user.isAdmin) {
    return
  }

  if (!user.triedPremium) {
    return navigateTo("/plan")
  }

  if (user.isPremiumExpired) {
    return navigateTo("/plan/expired")
  }
})
